import React from 'react';
import { HashLink } from 'react-router-hash-link';
import DOMPurify from 'dompurify';

// Props: none
function ElementDebugResponsive()
{
    return (
        <div className="debug-responsive-container">
            <div className="d-block d-sm-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xs</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8001/backend/main/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-sm-block d-md-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">sm</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8001/backend/main/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-md-block d-lg-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">md</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8001/backend/main/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-lg-block d-xl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">lg</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8001/backend/main/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xl-block d-xxl-none">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-none"></div>
                <span className="debug-responsive-text">xl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8001/backend/main/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
            <div className="d-none d-xxl-block">
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <div className="debug-responsive-block"></div>
                <span className="debug-responsive-text">xxl</span>
                <span className="debug-responsive-text-link"><a href="http://localhost:8001/backend/main/admin" target="_blank" rel="noopener noreferrer">backend</a></span>
            </div>
        </div>
    );
}

// Props: title, text, image
function ElementPanelContain(props)
{
    return (
        <React.Fragment>
            { /* XS, SM */ }
            <div className="col-12 d-block d-md-none">
                <div className="panel-container-mobile">
                    <ElementTitleH2 text={props.title} />
                    <p className="panel-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                    <div className="panel-image-contain" style={{backgroundImage: 'url(/image/'+props.image+'.jpg)'}}></div>
                </div>
            </div>

            { /* MD, LG, XL, XXL */ }
            <div className="col-6 d-none d-md-block">
                <div className="panel-container-desktop">
                    <ElementTitleH2 text={props.title} />
                    <p className="panel-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                    <div className="panel-image-contain" style={{backgroundImage: 'url(/image/'+props.image+'.jpg)'}}></div>
                </div>
            </div>
        </React.Fragment>
    );
}

// Props: title, text
function ElementPanelText(props)
{
    return (
        <React.Fragment>
            { /* XS, SM */ }
            <div className="col-12 d-block d-md-none">
                <div className="panel-container-text-mobile">
                    <ElementTitleH2 text={props.title} />
                    <p className="panel-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                </div>
            </div>

            { /* MD, LG, XL, XXL */ }
            <div className="col-6 d-none d-md-block">
                <div className="panel-container-text-desktop">
                    <ElementTitleH2 text={props.title} />
                    <p className="panel-text" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>
                </div>
            </div>
        </React.Fragment>
    );
}

// Props: type (light, gray or dark), category, title, text, buttons, tag
function ElementSlideshowContent(props)
{
    let style = {};
    switch (props.type)
    {
        case 'white':   style = {
                            color: '#000000'
                        }
                        break;

        case 'gray':    style = {
                            color: '#000000'
                        }
                        break;

        case 'black':   style = {
                            color: '#ffffff'
                        }
                        break;
                        
        default:        style = {
                            color: '#000000'
                        }
                        break;
    }
    
    let buttons = [];
    if (props.buttons !== null)
    {
        for (let i = 0; i < props.buttons.length; i++)
        {
            if (props.buttons[i]['buttontype'] === 'link')
                buttons.push(<a href={props.buttons[i]['to']} target="_blank" rel="noreferrer" className={props.buttons[i]['class']} key={i}>{props.buttons[i]['text']}<span className="slideshow-link-external-icon"></span></a>);
            
            if (props.buttons[i]['buttontype'] === 'hash')
                buttons.push(<React.Fragment key={i}><HashLink smooth to={'#'+props.buttons[i]['to']} className={props.buttons[i]['class']}>{props.buttons[i]['text']}</HashLink></React.Fragment>);
        }
    }
    
    return (
        <div className="row" style={style}>
            <div className="col-12 col-md-10 col-lg-8 col-xl-7">
                { /* XS, SM */ }
                <div className="d-block d-md-none">
                    <span className={'slideshow-category slideshow-content-type-'+props.type}>{props.category}</span>
                    { props.tag === 'h1' &&
                        <h1 className={'slideshow-title-mobile slideshow-content-type-'+props.type}>{props.title}</h1>
                    }
                    { props.tag === 'h3' &&
                        <h3 className={'slideshow-title-mobile slideshow-content-type-'+props.type}>{props.title}</h3>
                    }
                    <p className={'slideshow-text slideshow-content-type-'+props.type} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>

                    {buttons}
                </div>

                { /* MD */ }
                <div className="d-none d-md-block d-lg-none">
                    <span className={'slideshow-category slideshow-content-type-'+props.type}>{props.category}</span>
                    { props.tag === 'h1' &&
                        <h1 className={'slideshow-title-tablet slideshow-content-type-'+props.type}>{props.title}</h1>
                    }
                    { props.tag === 'h3' &&
                        <h3 className={'slideshow-title-tablet slideshow-content-type-'+props.type}>{props.title}</h3>
                    }
                    <p className={'slideshow-text slideshow-content-type-'+props.type} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>

                    {buttons}
                </div>
                
                { /* LG, XL, XXL */ }
                <div className="d-none d-lg-block">
                    <span className={'slideshow-category slideshow-content-type-'+props.type}>{props.category}</span>
                    { props.tag === 'h1' &&
                        <h1 className={'slideshow-title-desktop slideshow-content-type-'+props.type}>{props.title}</h1>
                    }
                    { props.tag === 'h3' &&
                        <h3 className={'slideshow-title-desktop slideshow-content-type-'+props.type}>{props.title}</h3>
                    }
                    <p className={'slideshow-text slideshow-content-type-'+props.type} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.text)}}></p>

                    {buttons}
                </div>
            </div>
        </div>
    );
}

// Props: slide index (int), slide current (int), type (light, gray or dark), category, title
function ElementSlideshowThumbnail(props)
{
    let style = {};
    switch (props.type)
    {
        case 'white':   if (props.selected)
                        {
                            style = {
                                color: '#000000'
                            }
                        }
                        else
                        {
                            style = {
                                color: '#8c8c8c'
                            }
                        }
                        break;

        case 'gray':    if (props.selected)
                        {
                            style = {
                                color: '#000000'
                            }
                        }
                        else
                        {
                            style = {
                                color: '#646464'
                            }
                        }
                        break;

        case 'black':   if (props.selected)
                        {
                            style = {
                                color: '#ffffff'
                            }
                        }
                        else
                        {
                            style = {
                                color: '#939393'
                            }
                        }
                        break;

        default:        if (props.selected)
                        {
                            style = {
                                color: '#000000'
                            }
                        }
                        else
                        {
                            style = {
                                color: '#8c8c8c'
                            }
                        }
                        break;
    }
    
    return (
        <div className="slideshow-thumbnail" style={style}>
            { props.selected === true &&
                <div className={'slideshow-thumbnail-timer-'+props.type}>
                    <div className={'slideshow-thumbnail-timer-animation-'+props.color}></div>
                </div>
            }

            { props.selected === false &&
                <div className={'slideshow-thumbnail-timer-'+props.type}>
                </div>
            }

            <div className="d-none d-lg-block">
                <div className="slideshow-thumbnail-category">
                    {props.category}
                </div>

                <div className="slideshow-thumbnail-text">
                    {props.title}
                </div>
            </div>
        </div>
    );
}

// Props: text
function ElementTitleH1(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h1 className="element-title-h1-mobile">{props.text}</h1>
            </div>
            <div className="d-none d-md-block">
                <h1 className="element-title-h1-desktop">{props.text}</h1>
            </div>
        </React.Fragment>
    );
}

// Props: text
function ElementTitleH2(props)
{
    return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <h2 className="element-title-h2-mobile">{props.text}</h2>
            </div>
            <div className="d-none d-md-block">
                <h2 className="element-title-h2-desktop">{props.text}</h2>
            </div>
        </React.Fragment>
    );
}

export { ElementDebugResponsive, ElementPanelContain, ElementPanelText, ElementSlideshowContent, ElementSlideshowThumbnail, ElementTitleH1, ElementTitleH2 };