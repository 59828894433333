import React from 'react';

import { SectionContent, SectionDisclaimer, SectionForm, SectionPrivacy, SectionProfile, SectionSalesRecommendationAI, SectionSalesRecommendationAI2, SectionServices, SectionSlideshow, SectionStatus, SectionTrendForecastingAI } from '../components/Section';

function PageDisclaimer(props)
{
    return (
        <React.Fragment>
            <SectionStatus
                debug={props.debug}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionDisclaimer
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </React.Fragment>
    );
}

function PageHomepage(props)
{
    return (
        <React.Fragment>
            <SectionStatus
                debug={props.debug}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionSlideshow
                debug={props.debug}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionContent
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            { /*
            <SectionSalesRecommendationAI  
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
            
            <SectionTrendForecastingAI
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
            */ }

            <SectionSalesRecommendationAI2  
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionProfile
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionServices
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionForm
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </React.Fragment>
    );
}

function PagePrivacy(props)
{
    return (
        <React.Fragment>
            <SectionStatus
                debug={props.debug}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionPrivacy
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </React.Fragment>
    );
}

export { PageDisclaimer, PageHomepage, PagePrivacy };